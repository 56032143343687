import React, { useState } from 'react';
import { Form, Button, Alert  } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";

const KontaktointiFormi = () => {
  const [formData, setFormData] = useState({
    sahposti: '',
    puhnum: '',
    yritys: '',
    lisatiedot: '',
    kontaktointitapa: [],
    recaptchaVerified: false
  });

  const [KontaktointiTaytto, setKontaktointiTaytto] = useState(false);
  
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      const updatedValues = formData.kontaktointitapa.includes(value)
        ? formData.kontaktointitapa.filter(item => item !== value)
        : [...formData.kontaktointitapa, value];
      setFormData({ ...formData, [name]: updatedValues });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const isRecaptchaChecked = formData.kontaktointitapa.includes('puhelin') || formData.kontaktointitapa.includes('sähköposti');
    if (!isRecaptchaChecked || !formData.recaptchaVerified) {
      setKontaktointiTaytto(true);
    }
    else{
      // Lähetä lomake
      event.currentTarget.submit();
    }
  };

  const handleRecaptchaChange = (value) => {
    setFormData({ ...formData, recaptchaVerified: !!value });
  };
  return (
    <Form className="form" onSubmit={handleSubmit} method="post" action="/Kontaktointi.php">
      {KontaktointiTaytto && <Alert variant="danger">Täytä kaikki kentät.</Alert>}
      
      <h2 className="mb-4">Miten haluat yhteydenoton?</h2>
      <Form.Check
        inline
        
        label="Puhelimitse"
        name="kontaktointitapa"
        type="checkbox"
        value="puhelin"
        checked={formData.kontaktointitapa.includes('puhelin')}
        onChange={handleInputChange}
        id="inline-checkbox-1"
      />
      <Form.Check
        inline
        
        label="Sähköpostilla"
        name="kontaktointitapa"
        type="checkbox"
        value="sähköposti"
        checked={formData.kontaktointitapa.includes('sähköposti')}
        onChange={handleInputChange}
        id="inline-checkbox-2"
      />

      <Form.Group className="mb-3" controlId="sahposti">
        <Form.Label>Sähköposti:</Form.Label>
        <Form.Control
          type="email"
          required
          name="sahposti"
          value={formData.sahposti}
          onChange={handleInputChange}
          placeholder="name@example.com"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="puhnum">
        <Form.Label>Puhelinnumero:</Form.Label>
        <Form.Control
          type="tel"
          required
          name="puhnum"
          value={formData.puhnum}
          onChange={handleInputChange}
          placeholder="+358 ** *** ***"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="yritys">
        <Form.Label>Yritys:</Form.Label>
        <Form.Control
          type="text"
          required
          name="yritys"
          value={formData.yritys}
          onChange={handleInputChange}
          placeholder="Maijan pirtti"
        />
      </Form.Group>

      <Form.Group className="mt-3 mb-5" controlId="lisatiedot">
        <Form.Label>Kirjoita lisätiedot tähän:</Form.Label>
        <Form.Control
          as="textarea"
          required
          rows={3}
          name="lisatiedot"
          value={formData.lisatiedot}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group className="d-flex justify-content-center" controlId="lisatiedot">
      <ReCAPTCHA className='mb-5'
        sitekey="6Lfju64pAAAAAO3Llpv3NsZx3mTwHKADcIlQUTkS"
        onChange={handleRecaptchaChange}
      />
      </Form.Group>
      <Button className="liilaButton mb-5" size="lg" type="submit"> 
        Lähetä
      </Button>
    </Form>
  );
};

export default KontaktointiFormi;