import React from "react";

import { Container } from "react-bootstrap";

import Slider from "react-infinite-logo-slider";

import logokauneuskulmasoma from "../logokauneuskulmasoma.png";
import logobeautyluvie from "../logobeautyluvie.jpg";
import logoF4 from "../logoF4.png";
import logo from "../Coda.png";

const LogoCarousel = () => {

  return (
    <>
      <Container className="mb-5">
          
          <Slider
            width="250px"
            duration={50}
            pauseOnHover={true}
            blurBorders={false}
            blurBoderColor={"#fff"}
          >
            <Slider.Slide className="yhteistyössäSlide">
              <a href="https://www.f4sport.fi" target="_blank">
                <img src={logoF4} alt="any" className="yhteistyölogoF4" />
              </a>
            </Slider.Slide>
            <Slider.Slide className="yhteistyössäSlide">
              <a href="https://www.beautyluvie.fi" target="_blank">
                <img
                  src={logobeautyluvie}
                  alt="any2"
                  className="yhteistyölogo"
                />
              </a>
            </Slider.Slide>
            <Slider.Slide className="yhteistyössäSlide">
              <a href="https://www.kauneuskulmasoma.fi" target="_blank">
                <img
                  src={logokauneuskulmasoma}
                  alt="any3"
                  className="yhteistyölogo"
                />
              </a>
            </Slider.Slide>
            <Slider.Slide className="yhteistyössäSlide">
              <a href="https://www.codacode.fi" target="_blank">
                <img src={logo} alt="any2" className="yhteistyölogo" />
              </a>
            </Slider.Slide>
          </Slider>
        </Container>

    </>
  );
};

export default LogoCarousel;