import React from 'react';
import Home from "./pages";
import './App.css';
import Navbar2 from "./components/NavBar";

function App() {
  return (
<>
    <Navbar2 />
       
          <Home />
</>

  );
}

export default App;