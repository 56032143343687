import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Container } from 'react-bootstrap';

const Hinnasto = () => {
    const [valitutVaihtoehdot, setValitutVaihtoehdot] = useState({
        sivujenMaara: '',
        toiminnallisuudet: [],
        logo: '',
        tarvitsetkoApuaSisallonKanssa: '',
        tarvitsetkoKuvia: '',
        tarvitsetkoTeksteja: ''
    });

    const [hinnasto, setHinnasto] = useState(200);

    useEffect(() => {
        let hinta = 200; // Perushinta

        // Lisää hintaa sivujen määrän perusteella
        switch (valitutVaihtoehdot.sivujenMaara) {
            case '1-3':
                hinta = 200;
                break;
            case '4-7':
                hinta = 400;
                break;
            case '8 ->':
                hinta = 600;
                break;
            default:
                break;
        }

        // Lisää hintaan tarvittavat muutokset
        valitutVaihtoehdot.toiminnallisuudet.forEach((toiminnallisuus) => {
            if (toiminnallisuus === 'Ei toiminnallisuuksia') {
                hinta += 0; // Lisää hintaan 0€, jos valittu
            }
            if (toiminnallisuus === 'lahjakortti') {
                hinta += 200; // Lisää hintaan 200€, jos valittu
            }
            if (toiminnallisuus === 'Sisäänkirjautuminen') {
                hinta += 50; // Lisää hintaan 50€, jos valittu
            }
            if (toiminnallisuus === 'rekisteröinti') {
                hinta += 200; // Lisää hintaan 200€, jos valittu
            }
            if (toiminnallisuus === 'Pelillistäminen') {
                hinta += 50; // Lisää hintaan 50€, jos valittu
            }
            if (toiminnallisuus === 'Logokaruselli') {
                hinta += 25; // Lisää hintaan 25€, jos valittu
            }
            if (toiminnallisuus === 'Kuvakaruselli') {
                hinta += 20; // Lisää hintaan 20€, jos valittu
            }
            if (toiminnallisuus === 'Lomake') {
                hinta += 50; // Lisää hintaan 50€, jos valittu
            }
            if (toiminnallisuus === 'Upotus') {
                hinta += 20; // Lisää hintaan 20€, jos valittu
            }
            if (toiminnallisuus === 'Kyllä') {
                hinta += 150; // Lisää hintaan 50€, jos valittu
            }
        });

        // Päivitä hinta
        setHinnasto(hinta);
    }, [valitutVaihtoehdot]);

    // Radiobuttonin muutoskäsittelijä
    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        setValitutVaihtoehdot({ ...valitutVaihtoehdot, [name]: value });
    };

    // Checkboxien muutoskäsittelijä
    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        const value = e.target.value;

        // Tallenna muutokset valittuihin vaihtoehtoihin
        if (checked) {
            setValitutVaihtoehdot((prevVaihtoehdot) => ({
                ...prevVaihtoehdot,
                toiminnallisuudet: [...prevVaihtoehdot.toiminnallisuudet, value]
            }));
        } else {
            setValitutVaihtoehdot((prevVaihtoehdot) => ({
                ...prevVaihtoehdot,
                toiminnallisuudet: prevVaihtoehdot.toiminnallisuudet.filter((item) => item !== value)
            }));
        }
    };

    return (
        <>
        {/* Hinnasto */ }
        <Container>
        <Row className='d-flex justify-content-center'>
            <Col className="col-lg-12 col-12 ">
                <Form className="hinnasto">

                    {/* Sivujen määrä */}
                    <Col className="d-flex justify-content-center">
                        <Form.Label className='mb-3 hinnastoOtsikko'>Sivujen määrä:</Form.Label>
                    </Col>
                    <Col className="col-12  d-flex justify-content-center">
                        <Col className="col-lg-3 col-12  d-flex justify-content-start">
                        <div className="mb-3">
                            <Form.Check
                                inline
                                label="1-3"
                                name="sivujenMaara"
                                value="1-3"
                                type="radio"
                                onChange={handleRadioChange}
                            />
                            <Form.Check
                                inline
                                label="4-7"
                                name="sivujenMaara"
                                value="4-7"
                                type="radio"
                                onChange={handleRadioChange}
                            />
                            <Form.Check
                                inline
                                label="8 ->"
                                name="sivujenMaara"
                                value="8 ->"
                                type="radio"
                                onChange={handleRadioChange}
                            />
                        </div>
                    </Col>
                    </Col>
                    {/* Toiminnallisuudet */}
                    <Col className=" d-flex justify-content-center">
                        <Form.Label className='mb-3 hinnastoOtsikko' >Toiminnallisuudet:</Form.Label>
                    </Col>

                    <Row className="d-flex justify-content-center">
                        <Col className="col-12  d-flex justify-content-center">
                        <Col className="col-lg-3 col-12  d-flex justify-content-start">
                            <Form.Check
                                inline
                                label="Ei toiminnallisuuksia"
                                type={'checkbox'}
                                id={"ei-toiminnallisuuksia"}
                                value="Ei toiminnallisuuksia"
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        </Col>
                        <Col className="col-12  d-flex justify-content-center">
                        <Col className="col-lg-3 col-12 d-flex justify-content-start">
                            <Form.Check
                                inline
                                label="Ohjelmoitu lahjakortti"
                                type={'checkbox'}
                                id={"lahjakortti"}
                                value="lahjakortti"
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        </Col>
                       
                        <Col className="col-12  d-flex justify-content-center">
                        <Col className="col-lg-3 col-12  d-flex justify-content-start">
                            <Form.Check
                                inline
                                label="Sisällön upotus (Lahjakortti, Facebook, jne.)"
                                type={'checkbox'}
                                id={"upotus"}
                                value="Upotus"
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        </Col>
                        <Col className="col-12  d-flex justify-content-center">
                        <Col className="col-lg-3 col-12  d-flex justify-content-start">
                            <Form.Check
                                inline
                                label="Pelillistäminen"
                                type={'checkbox'}
                                id={"pelillistaminen"}
                                value="Pelillistäminen"
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        </Col>
                        <Col className="col-12  d-flex justify-content-center">
                        <Col className="col-lg-3 col-12  d-flex justify-content-start">
                            <Form.Check
                                inline
                                label="Logokaruselli"
                                type={'checkbox'}
                                id={"logokaruselli"}
                                value="Logokaruselli"
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        </Col>
                        <Col className="col-12  d-flex justify-content-center">
                        <Col className="col-lg-3 col-12  d-flex justify-content-start">
                            <Form.Check
                                inline
                                label="Kuvakaruselli"
                                type={'checkbox'}
                                id={"kuvakaruselli"}
                                value="Kuvakaruselli"
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        </Col>
                        <Col className="col-12  d-flex justify-content-center">
                        <Col className="col-lg-3 col-12  d-flex justify-content-start">
                            <Form.Check
                                inline
                                label="Lomake"
                                type={'checkbox'}
                                id={"lomake"}
                                value="Lomake"
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        </Col>
                        <Col className="col-12  d-flex justify-content-center">
                        <Col className="col-lg-3 col-12  d-flex justify-content-start">
                            <Form.Check
                                inline
                                label="Sisäänkirjautuminen"
                                type={'checkbox'}
                                id={"Sisäänkirjautuminen"}
                                value="Sisäänkirjautuminen"
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        </Col>

                        <Col className="col-12  d-flex justify-content-center">
                        <Col className="col-lg-3 col-12  d-flex justify-content-start">
                            <Form.Check
                                inline
                                label="Sisäänkirjautuminen ja rekisteröityminen"
                                type={'checkbox'}
                                id={"Sisäänkirjautuminen ja rekisteröityminen"}
                                value="rekisteröinti"
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        </Col>
                    </Row>

                    {/* Logo */}
                    <Col className="col-12 d-flex justify-content-center">
                        <Form.Label className='mb-3 mt-3 hinnastoOtsikko'>Tarvitsetko logon?</Form.Label>
                    </Col>
                    <Row>
                    <Col className="col-12  d-flex justify-content-center">
                        <Col className="col-lg-3 col-12  d-flex justify-content-start">
                            <Form.Check
                                inline
                                label="Ei"
                                type={'checkbox'}
                                id={"ei-toiminnallisuuksia"}
                                value="Ei toiminnallisuuksia"
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        </Col>
                        <Col className="col-12  d-flex justify-content-center">
                        <Col className="col-lg-3 col-12  d-flex justify-content-start">
                            <Form.Check
                                inline
                                label="Kyllä"
                                type={'checkbox'}
                                id={"Kyllä"}
                                value="Kyllä"
                                onChange={handleCheckboxChange}
                            />
                        </Col>
                        </Col>
                    </Row>
                </Form >
            </Col >
            {/* Hinta */}
            <Col className=" col-lg-12 col-12 d-flex justify-content-center align-items-center">
                <h1 className="hinnastoSumma">Alk. {hinnasto}€</h1>
            </Col>
        </Row >
    </Container>
    </>
  );
};

export default Hinnasto;
