import React from "react";
import logo from "../Coda.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faTiktok,
    faInstagram,
  } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {

  return (
    <>
      <footer className="footer">
        <div className="container py-4">
          <div className="row">
            <div className="col-md-4">
              <div className="py-4">
                <img src={logo} alt="Kuva" className="logoCodaCode" />
              </div>
              <p className="tekstiFooter">
                Tarjoamme laadukkaita ja edullisia verkkosivuja yrityksille sekä
                yhdistyksille. Meiltä saat myös hakukoneoptimointiin tarvittavat
                työkalut sekä analyysit.
              </p>
            </div>
            <div className="viiva col-md-4 mt-5">
              <h5 className="otsikkofooter">Yhteystiedot</h5>
              <ul className="list-unstyled">
                <li>040 3557780</li>
                <li>asiakaspalvelu@codacode.fi</li>
                <li>Y-tunnus 3431432-3</li>
              </ul>
              {/*Tässä footerin social iconit */}
              <div>
                <a href="https://www.facebook.com/people/CodaCode-Oy/61558344411736/" target="_blank">
                  <FontAwesomeIcon icon={faFacebook} className="custom-icon" />
                </a>
                <a href="https://www.instagram.com/codacodeoy" target="_blank">
                  <FontAwesomeIcon icon={faInstagram} className="custom-icon" />
                </a>
                <a href="https://www.tiktok.com/@codacode/" target="_blank">
                  <FontAwesomeIcon icon={faTiktok} className="custom-icon" />
                </a>
              </div>
            </div>
            <div className="col-md-4 mt-5">
              <h5 className="otsikkofooter">Hyödyllisiä linkkejä</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="https://www.wordpress.com/" target="_blank">
                    WordPress
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ukko.fi/yrittajyyskoulu/hakukoneoptimointi-ja-5-tarkeinta-seo-vinkkia-uudelle-pienyrittajalle/"
                    target="_blank"
                  >
                    Hakukoneoptimointi
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.mediamaisteri.com/blog/pelillistaminen-opetuksessa-ja-oppimisessa"
                    target="_blank"
                  >
                    Pelillistäminen
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p>&copy; 2024 CodaCode Oy</p>
      </footer>
    </>
  );
};

export default Footer;