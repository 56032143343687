import React, { Component } from "react";
import "../App.css";
import {
  Container,
  Row,
  Col,
  Carousel,
} from "react-bootstrap";
import NäyttöSoma from "../Somareferenssi.png";
import NäyttöF4 from "../F4referenssi.png";
import NäyttöMMM from "../MMMreferenssi.png"



export default class ReferenssiCarousel extends Component {

  render() {
    return (
      <Container>
      <Row>
        <Col className="d-flex justify-content-center">
          <Carousel data-bs-theme="light" touch="true"> 
      <Carousel.Item>
      <a href="https://kauneuskulmasoma.fi/" target="_blank">
        <img
          className="d-block w-100"
          src= {NäyttöSoma}
          alt="First slide"
        />
         </a>
      </Carousel.Item>
      <Carousel.Item>
      <a href="https://www.f4sport.fi/" target="_blank">
        <img
          className="d-block w-100"
          src={NäyttöF4}
          alt="Second slide"
        />
       </a>
      </Carousel.Item>
      <Carousel.Item>
      <a href="https://www.mmmkuljetusoy.fi/" target="_blank">
        <img
          className="d-block w-100"
          src= {NäyttöMMM}
          alt="Third slide"
        />
      </a>
      </Carousel.Item>
    </Carousel>
        </Col>
      </Row>
      </Container>
    );
  }
}