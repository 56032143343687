import React from "react";
import { useState } from 'react';
import {
  Container,
  Navbar,
  Nav,
  Button
} from "react-bootstrap";
import { Link } from 'react-scroll';
import logo from "../Coda.png";

const Navbar2 = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Navbar className="navbar fixed-top navbar-dark" expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Brand>
            <a href="#">
              <img src={logo} alt="Kuva" className="logoCodaCode" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggler custom-toggler" onClick={toggleExpanded} />
          <Navbar.Collapse className="navbar-collapse" id="basic-navbar-nav">
            <Nav className="ms-auto text-center">
              <Link className="nav-link" to="Palvelut" spy={true}
                smooth={true}
                offset={-70}
                duration={500} onClick={() => setExpanded(false)}>
                Palvelut 
              </Link>
              <Link className="nav-link" to="Toitamme" spy={true}
                smooth={true}
                offset={-70}
                duration={500} onClick={() => setExpanded(false)}>
                Töitämme
              </Link>
              <Link className="nav-link" to="Hinnasto" spy={true}
                smooth={true}
                offset={-70}
                duration={500} onClick={() => setExpanded(false)}>
                Hinnasto
              </Link>
              <Link className="nav-link" to="OtaYhteytta" spy={true}
                smooth={true}
                offset={-70}
                duration={500} onClick={() => setExpanded(false)}>
                Ota yhteyttä
              </Link>
              <Button variant="outline-light" className="btn-custom" onClick={() => { setExpanded(false); window.open('https://blog.codacode.fi', '_blank'); }}>Blogi</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Navbar2;