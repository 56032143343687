// UusiKomponentti.js
import React from "react";
import { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../App.css";
import { Container, Button, Row, Col,Spinner } from "react-bootstrap";

import Netti from "../Netti4.png";
import Email from "../email3.png";

import ReferenssiCarousel from "../components/ReferenssiCarousel";
import LogoCarousel from "../components/LogoCarousel";
import Hinnasto from "../components/Hinnasto";
import Footer from "../components/Footer";
import KontaktointiFormi from "../components/KontaktointiFormi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGamepad,
  faGlobe,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  const [slideIn, setSlideIn] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);
  const [isVisible5, setIsVisible5] = useState(false);
  useEffect(() => {
    // Kun komponentti renderöidään, asetetaan slideIn-tilaksi true, jotta animaatio käynnistyy
    setSlideIn(true);
    function handleScroll1() {
      // Tarkista, kun ensimmäinen elementti tulee näkyviin näytön alareunassa
      const element1 = document.getElementById("Toitamme");
      const bottom1 = element1.getBoundingClientRect().bottom;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      if (bottom1 <= windowHeight) {
        setIsVisible1(true);
        // Poista scroll-tapahtumien kuuntelija, kun ensimmäinen elementti on tullut näkyviin
        window.removeEventListener("scroll", handleScroll1);
      }
    }

    function handleScroll2() {
      // Tarkista, kun toinen elementti tulee näkyviin näytön alareunassa
      const element2 = document.getElementById("Hinnasto");
      const bottom2 = element2.getBoundingClientRect().bottom;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      if (bottom2 <= windowHeight) {
        setIsVisible2(true);
        // Poista scroll-tapahtumien kuuntelija, kun toinen elementti on tullut näkyviin
        window.removeEventListener("scroll", handleScroll2);
      }
    }
    function handleScroll3() {
      // Tarkista, kun toinen elementti tulee näkyviin näytön alareunassa
      const element2 = document.getElementById("Yhteistyossa");
      const bottom2 = element2.getBoundingClientRect().bottom;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      if (bottom2 <= windowHeight) {
        setIsVisible3(true);
        // Poista scroll-tapahtumien kuuntelija, kun toinen elementti on tullut näkyviin
        window.removeEventListener("scroll", handleScroll3);
      }
    }
    function handleScroll4() {
      // Tarkista, kun toinen elementti tulee näkyviin näytön alareunassa
      const element2 = document.getElementById("OtaYhteytta");
      const bottom2 = element2.getBoundingClientRect().bottom;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      if (bottom2 <= windowHeight) {
        setIsVisible4(true);
        // Poista scroll-tapahtumien kuuntelija, kun toinen elementti on tullut näkyviin
        window.removeEventListener("scroll", handleScroll4);
      }
    }
    function handleScroll5() {
      // Tarkista, kun toinen elementti tulee näkyviin näytön alareunassa
      const element2 = document.getElementById("Palvelut");
      const bottom2 = element2.getBoundingClientRect().bottom;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      if (bottom2 <= windowHeight) {
        setIsVisible5(true);
        // Poista scroll-tapahtumien kuuntelija, kun toinen elementti on tullut näkyviin
        window.removeEventListener("scroll", handleScroll5);
      }
    }

    window.addEventListener("scroll", handleScroll1);
    window.addEventListener("scroll", handleScroll2);
    window.addEventListener("scroll", handleScroll3);
    window.addEventListener("scroll", handleScroll4);
    window.addEventListener("scroll", handleScroll5);
    return () => {
      window.removeEventListener("scroll", handleScroll1);
      window.removeEventListener("scroll", handleScroll2);
      window.removeEventListener("scroll", handleScroll3);
      window.removeEventListener("scroll", handleScroll4);
      window.removeEventListener("scroll", handleScroll5);
    };
  }, []);

  return (
    <>
      {/* Tästä alkaa otsikkoteksti ja kuva, jotka ilmestyvät animaatioina */}
      <div className="backround4">
        <Container>
          <Row>
            <Col xs lg="8">
              <h2 className={`otsikkoteksti ${slideIn ? "slide-in-text" : ""}`}>
                UUDET KOTISIVUT<br></br> YRITYKSELLE TAI
                <br></br> YHDISTYKSELLE
              </h2>
              <p
                className={`otsikkotekstipieni ${
                  slideIn ? "slide-in-text-pieni" : ""
                }`}
              >
                Rakennamme digitaalista tulevaisuutta yksi sivu kerrallaan.
              </p>
            </Col>
            <Col xs lg="4" className="nettikuvaBox">
              <img
                src={Netti}
                alt="Kuva"
                className={`nettikuva ${slideIn ? "slide-in-image" : ""}`}
              />
            </Col>
          </Row>
        </Container>

        {/* Tästä alkaa palvelut osio */}
        <Container className="space">
        <h2
          id="Palvelut"
          className={isVisible5 ? "otsikkoteksti2 slide-in" : "otsikkoteksti2"}
        >
          PALVELUT
        </h2>
          <Row>
            <Col
              xs
              lg="4"
              className="hover-area d-flex flex-column align-items-center justify-content-center"
            >
              <div className="verkkosivutBackground">
                <div className="verkkosivutIcon">
                  <FontAwesomeIcon icon={faGlobe} />
                </div>
              </div>

              <Col className="mt-3 d-flex flex-column align-items-center justify-content-center text-center">
                <h2 className="palveluOtsikko">VERKKOSIVUT</h2>
                <p className="palveluTeksti">
                  Hyvät kotisivut luovat ammattimaisen ensivaikutelman
                  potentiaalisille asiakkaille. Selkeä ja houkutteleva
                  suunnittelu voi erottaa yrityksen kilpailijoista. Muista,
                  ensivaikutelman voi tehdä vain kerran.
                </p>
                <OffCanvasExample
                  id={1}
                  key={2}
                  placement={"start"}
                  name={"Lue lisää"}
                />
              </Col>
            </Col>
            <Col
              xs
              lg="4"
              className="hover-area d-flex flex-column align-items-center justify-content-center"
            >
              <div className="SEOBackground">
                <div className="SEOIcon">
                  <FontAwesomeIcon icon={faChartLine} />
                </div>
              </div>

              <Col className="mt-3 d-flex flex-column align-items-center justify-content-center text-center">
                <h2 className="palveluOtsikko">HAKUKONEOPTIMOINTI</h2>
                <p className="palveluTeksti">
                  Kun halutaan näkyvyyttä yritykselle, hakukoneoptimointi eli
                  SEO (search engine optimization) on ehdottoman tärkeää. Hyvin
                  rakennetut verkkosivut ja oikein käytetyt avainsanat nostavat
                  paikkaasi hakutuloksissa.
                </p>

                <OffCanvasExample
                  id={2}
                  key={2}
                  placement={"start"}
                  name={"Lue lisää"}
                />
              </Col>
            </Col>
            <Col
              xs
              lg="4"
              className="hover-area d-flex flex-column align-items-center justify-content-center"
            >
              <div className="pelillistaminenBackground">
                <div className="pelillistaminenIcon">
                  <FontAwesomeIcon icon={faGamepad} />
                </div>
              </div>

              <Col className="mt-3 d-flex flex-column align-items-center justify-content-center text-center">
                <h2 className="palveluOtsikko">PELILLISTÄMINEN</h2>
                <p className="palveluTeksti">
                  Pelillistäminen tarkoittaa pelin ominaisuuksien, kuten
                  palkintojen, haasteiden ja kilpailun, integroimista
                  ei-pelillisiin ympäristöihin, kuten koulutukseen ja
                  motivaation sekä osallistumisen lisäämiseksi.
                </p>
                <Button style={{borderRadius:"15px", backgroundColor: "#a3c4c3", color:" #052e2e", padding:"9px", borderColor:"#a3c4c3"}}> 
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Tulossa
      </Button>
              </Col>
            </Col>
          </Row>
        </Container>

        {/* Tästä alkaa Töitämme osio */}
        <h2
          id="Toitamme"
          className={isVisible1 ? "otsikkoteksti2 slide-in" : "otsikkoteksti2"}
        >
          TÖITÄMME
        </h2>

        <ReferenssiCarousel />

        {/* Tästä alkaa Hinnasto osio */}
        <h2
          id="Hinnasto"
          className={isVisible2 ? "otsikkoteksti2 slide-in" : "otsikkoteksti2"}
        >
          HINNASTO
        </h2>
       <Hinnasto/>

        {/* Tästä alkaa yhteistyössä osio */}
        <h2
          id="Yhteistyossa"
          className={isVisible3 ? "otsikkoteksti2 slide-in" : "otsikkoteksti2"}
        >
          YHTEISTYÖSSÄ
        </h2>

        <LogoCarousel />

        {/*Tästä alkaa lomake*/}
        <Container className="text-center">
          <Row>
            <Col className="d-flex justify-content-center">
              <Col className="mt-5" xs lg="10">
                <h2
                  id="OtaYhteytta"
                  className={
                    isVisible4 ? "otsikkoteksti2 slide-in" : "otsikkoteksti2"
                  }
                >
                  OTA YHTEYTTÄ
                </h2>
                <p className="teksti">
                  Tässä voit kysyä sinua askarruttavista asioista, pyytää
                  hinta-arvioita tai vaikka ideoida. Lava on sinun! <br></br>
                  Pyrimme vastaamaan mahdollisimman nopeasti. Maksimissaan
                  kolmen arkipäivän kuluessa.<br></br>
                  Psst...Voit myös lähettää meille suoraan sähköpostia.
                </p>
                <img src={Email} alt="email" className="emailkuva" />
              </Col>
            </Col>
            <Col className="mt-5 d-flex justify-content-center">
              <Col xs lg="10" className=" d-flex justify-content-center">
              <KontaktointiFormi />
              
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
      
      {/*Tästä alkaa Footer */}
      <Footer />
    </>
  );
};

function OffCanvasExample({ name, id, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(props);
  let otsikko2 = "";
  let otsikko3 = "";
  let teksti = "";
  let teksti2 = "";
  let teksti3 = "";
  let teksti4 = "";
  let teksti5 = "";
  let teksti6 = "";

  if (id === 1) {
    teksti =
      "WordPress tarjoaa monipuolisen ja joustavan ratkaisun verkkosivustojen luomiseen ja ylläpitoon, ja se sopii niin aloittelijoille kuin kokeneemmillekin käyttäjille. Sen laaja yhteisö ja jatkuva kehitys varmistavat, että se pysyy kilpailukykyisenä ja ajan tasalla.";
    teksti2 =
      "Käyttäjäystävällisyys: WordPress on suunniteltu niin, että se on helppokäyttöinen, jopa niille, joilla ei ole syvällistä teknistä osaamista.";
    teksti3 =
      "Laajennettavuus: WordPressin voima piilee sen monipuolisissa lisäosissa ja teemoissa. Voit helposti laajentaa sivustoasi lisäämällä erilaisia ominaisuuksia ja ulkoasuja ilman syvällistä ohjelmointiosaamista.";
    teksti4 =
      "Jatkuvat päivitykset: WordPress päivittyy säännöllisesti turvallisuuden ja suorituskyvyn parantamiseksi. Päivitykset ovat helppoja asentaa, ja ne takaavat sivustosi pysyvän ajan tasalla.";
    teksti5 =
      "Helppo ylläpito: Voit päivittää itse sisältöä helposti käyttöliittymän kautta.";
    teksti6 =
      "Meiltä löytyy myös osaamista ohjelmoinnissa, mikä mahdollistaa monipuolisten verkkosivuratkaisujen toteuttamisen eri ohjelmointikielillä.";
    otsikko2 = "WordPress sivut";
    otsikko3 = "Räätälöityjä verkkoratkaisuja";
  }
  if (id === 2) {
    teksti =
      "Hakukoneoptimointi on helppo ja halpa tapa saada näkyvyyttä ja vierailijoita nettisivuille. Näitä asioita voidaan esimerkiksi mitata:";
    teksti2 =
      "Hakukoneiden liikenne: Analytiikka voi kertoa, kuinka paljon liikennettä sivusto saa eri hakukoneista. Tämä auttaa hahmottamaan, kuinka hyvin sivusto sijoittuu eri hakukoneissa.";
    teksti3 =
      "Avainsanojen suorituskyky: Analytiikka voi näyttää, mitkä avainsanat tuovat eniten liikennettä sivustolle hakukoneista. ";
    teksti4 =
      "Poistumisprosentit: Analytiikka voi näyttää, kuinka suuri osa kävijöistä poistuu sivustolta heti saavuttuaan. ";
    teksti5 =
      "Konversioprosentit: Konversioanalytiikka voi kertoa, kuinka hyvin sivusto muuntaa kävijöitä toivotuiksi toimiksi, kuten ostoksiin, tilauksiin tai yhteydenottoihin. ";
    teksti6 =
      "Hakukoneoptimoinnissa tärkeää on nettisivun sisällön laatu ja tekotapa. Hakukone botit nostavat sivua korkeammalle hakutuloksissa, jos ne luokittelevat sivun laadukkaaksi ja osuvaksi hakusanaan.";
    otsikko2 = "Hakukoneoptimointi";
    otsikko3 = "";
  }
  if (id === 3) {
    teksti = "Pelillistäminen kokeilu";
  }

  return (
    <>
      <Button onClick={handleShow} className="me-2 offButton">
        {name}
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        className="backround4"
        {...props}
      >
        <Offcanvas.Header
          closeButton
          style={{ backgroundColor: "#23c9f3" }}
          className="d-flex justify-content-center palveluTeksti"
        ></Offcanvas.Header>
        <Offcanvas.Body className="main-content palveluTeksti">
          <h3>{otsikko2}</h3>
          <p>{teksti}</p>
          <ul className="text-start">
            <li>{teksti2}</li>
            <li className="mt-3">{teksti3}</li>
            <li className="mt-3">{teksti4}</li>
            <li className="mt-3">{teksti5}</li>
          </ul>
          <h3>{otsikko3}</h3>
          <p>{teksti6}</p>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Home;